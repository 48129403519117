<template>
    <div class="app-container">
        <el-row :gutter="10">
            <el-col :xs="11" :sm="11" :md="11" :lg="11" :xl="11">
                <el-alert
                        :title="$t('ops.redisManager.redisList')"
                        :closable="false"
                        show-icon>
                </el-alert>
                <el-row :class="{ leftBottom:flagLeft }">
                    <el-input
                              :placeholder="$t('commons.searchPhrase')"
                              v-model="filterText">
                    </el-input>
                    <el-tree
                            :filter-node-method="filterNode"
                            :data="data"
                            :highlight-current="true"
                            ref="tree"
                            node-key="id"
                            show-checkbox>
                        <span class="custom-tree-node" slot-scope="{ node, data }"
                              @mouseenter="mouseoverNode(node)"
                              @mouseleave="mouseoutNode(node)">
                            <span @click="clickNode(node,data)">{{ node.label }}</span>
                            <template v-if="node.data.show">
                            <span :style="{ marginLeft:'30px' }">
                              <el-button
                                      type="text"
                                      @click="() => removeNode(node, data)">
                                <i class="el-icon-delete delete-btn"></i>
                              </el-button>
                            </span>
                        </template>
                        </span>
                    </el-tree>
                </el-row>
            </el-col>
            <el-col :xs="11" :sm="11" :md="11" :lg="11" :xl="11" :offset="2">
                <el-alert
                        :title="$t('ops.redisManager.redisContent')"
                        :closable="false"
                        show-icon>
                </el-alert>
                <el-button size="mini" type="primary" :class="{ floatBtn:floBtn }" @click="saveRedisValue(redis.form.value,redis.form.propertyValue)">
                    {{$t('commons.save') }}
                </el-button>
                <el-form :model="redis.form" role="form" label-width="150px">
                    <el-form-item :style="{ marginTop:'20px' }" :label="$t('ops.redisManager.redisNameColon')" prop="displayText">
                        <el-input v-model="redis.form.value" autocomplete="off" :readonly="true"></el-input>
                    </el-form-item>
                    <template v-if="dataType === 'string'">
                        <el-form-item :label="$t('ops.redisManager.redisValueColon')" prop="href">
                            <el-input type="textarea" :rows="25" v-model="redis.form.propertyValue" autocomplete="off"></el-input>
                        </el-form-item>
                    </template>
                    <template v-if="dataType === 'hash'">
                        <el-table
                                :data="tableData"
                                style="width: 100%">
                            <el-table-column
                                    prop="key"
                                    :label="$t('ops.redisManager.key')"
                                    width="180">
                                <template slot-scope="scope">
                                    <template v-if="scope.row.edit">
                                        <el-input v-model="scope.row.key"/>
                                    </template>
                                    <span v-else>{{ scope.row.key }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="value"
                                    :label="$t('ops.redisManager.value')">
                                <template slot-scope="scope">
                                    <template v-if="scope.row.edit">
                                        <el-input v-model="scope.row.value"/>
                                    </template>
                                    <span v-else>{{ scope.row.value }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    :label="$t('commons.actions')"
                                    width="210">
                                <template slot-scope="scope">
                                    <el-button
                                            size="mini"
                                            @click="addHashKey(scope.row)">{{$t('commons.add')}}
                                    </el-button>
                                    <template v-if="scope.row.edit">
                                        <el-button :disabled="scope.row.processing" size="mini" type="warning"
                                                   @click="cancelEdit(scope.row)" >{{$t('commons.cancel') }}
                                        </el-button>
                                        <el-button :loading="scope.row.processing" type="success" size="mini"
                                                   @click="handleSave(scope.row)">{{$t('commons.save') }}
                                        </el-button>
                                    </template>
                                    <template v-else>
                                        <el-button
                                                size="mini"
                                                :disabled="scope.row.processing"
                                                @click="editHandle(scope.row)">{{$t('commons.edit') }}
                                        </el-button>
                                        <el-button
                                                size="mini"
                                                type="danger"
                                                :loading="scope.row.processing"
                                                @click="deleteHashKey(scope.row)">{{$t('commons.delete') }}
                                        </el-button>
                                    </template>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import {getRedisList,getRedisValue,updateRedisValue,updateRedisHashValue,deleteRedisKey,deleteRedisHashKey} from '@/api/ruge/ops/ops'
    import {listToTree} from '@/utils/tree'

    export default {
        name: "redisManager",
        watch: {
            filterText(val) {
                this.$refs.tree.filter(val);
            }
        },
        data() {
            return {
                filterText: '',
                path : null,
                data: [],
                defaultProps: {
                    children: 'children',
                    label: 'label',
                    show : 'show'
                },
                flagLeft:true,
                floBtn:true,
                activeName: '1',
                redisName : '1',
                dataType: null,
                tableData: null,
                redis : {
                    form : {
                        value : null,
                        propertyValue : null
                    }
                }
            }
        },
        created() {
            this.getRedisList();
        },
        methods: {
            getRedisList() {
                getRedisList().then(response => {
                    let data = listToTree(response,"nodeId","parentId");
                    this.recursiveAttr(data)
                })
            },
            filterNode(value, data) {
                if (!value) return true;
                return data.label.indexOf(value) !== -1;
            },
            //递归修改属性及数据结构
            recursiveAttr(row){
                let data = row.map(v => {
                    v.label = v.value;
                    v.id = v.nodeId;
                    v.show = false;
                    if(v.children != undefined){
                        this.recursiveAttr(v.children);
                    }
                    return v
                })
                this.data = data;
            },
            //点击查询Redis Key数据
            clickNode(node){
                if(node.isLeaf){
                    let path = node.data.nodeName;
                    this.getKeyPath(node,path)
                    this.redis.form.value = this.path;
                    getRedisValue({"pathKey" : this.redis.form.value}).then(response => {
                        //判断值类型
                        if(response.string){//string
                            this.dataType = "string";
                            this.redis.form.propertyValue = response.string;
                        }
                        if(response.hash){//hash
                            this.dataType = "hash";
                            let map = response.hash;
                            let objList = [];
                            for(let key in map){
                                let obj = new Object();
                                obj.edit = false;
                                obj.key = key;
                                obj.value = map[key];
                                obj.isData = true;
                                objList.push(obj);
                            }
                            this.tableData = objList;
                        }
                    })
                }
            },
            //添加Hash Key
            addHashKey(){
                let obj = {
                    "isData": false,
                    "key" : "",
                    "value" : "",
                    "edit" : true,
                }
                this.tableData.push(obj);
            },
            //删除Hash Key
            deleteHashKey(row){
                this.$confirm(this.$t('message.deleteConfirm'), this.$t('commons.warning'), {
                    confirmButtonText: this.$t('commons.confirm'),
                    cancelButtonText: this.$t('commons.cancel'),
                    type: 'warning'
                }).then(() => {
                    let param = {"pathKey": this.redis.form.value,"hashKey" : row.key}
                    deleteRedisHashKey(param).then(() => {
                        const index = this.tableData.indexOf(row);
                        this.tableData.splice(index, 1);
                        // 成功提示
                        this.$message({
                            type: 'success',
                            message: this.$t('message.deleteSuccess')
                        });
                    }).catch(error => {
                        console.log(`未删除，原因 => ${error}`)
                    })
                });
            },
            //取消编辑 Hash Key
            cancelEdit(row) {
                if(!row.isData){
                    const index = this.tableData.indexOf(row);
                    this.tableData.splice(index, 1);
                }else{
                    this.restoreOriginal4Key(row);
                }
                row.edit = false;
            },
            //编辑Hash Key
            editHandle(row){
                this.setOriginal4Key(row)
                row.edit = true
            },
            //保存Hash Key
            handleSave(row){
                let param = {"pathKey": this.redis.form.value,"hashKey" : row.key,"value":row.value}
                updateRedisHashValue(param).then(() => {
                    row.edit = false;
                    row.isData = true;
                    // 成功提示
                    this.$message({
                        type: 'success',
                        message: this.$t('message.saveSuccess')
                    });
                }).catch(error => {
                    console.log(`未保存，原因 => ${error}`)
                })
            },
            // 保存原有值，用于取消编辑
            setOriginal4Key(data) {
                data.originalKey = data.key||null;
                data.originalValue = data.value||null;
            },
            // 还原原有值，用于取消编辑
            restoreOriginal4Key(data) {
                data.key = data.originalKey;
                data.value = data.originalValue;
            },
            mouseoverNode(node){
                node.data.show = true;
            },
            mouseoutNode(node){
                node.data.show = false;
            },
            //获取key全路径
            getKeyPath(node,path){
                if(node.parent == null){
                    this.path = path;
                }else{
                    let parent = node.parent;
                    if(parent.data.nodeName != undefined){
                        path  = parent.data.nodeName +":" + path;
                    }
                    this.getKeyPath(parent,path.trim());
                }
            },
            //保存Redis值(string)
            saveRedisValue(pathKey,value){
                updateRedisValue({"pathKey":pathKey,"value":value}).then(() =>{
                    // 成功提示
                    this.$message({type: 'success',message: this.$t('message.saveSuccess')});
                }).catch(error => {
                    console.log(`保存失败，原因 => ${error}`)
                })
            },
            //删除Redis Key
            removeNode(node,data){
                this.$confirm(this.$t('message.deleteConfirm'), this.$t('commons.warning'), {
                    confirmButtonText: this.$t('commons.confirm'),
                    cancelButtonText: this.$t('commons.cancel'),
                    type: 'warning'
                }).then(() => {
                    let path = node.data.nodeName;
                    this.getKeyPath(node,path)
                    deleteRedisKey({"pathKey":this.path}).then(() =>{
                        // 成功提示
                        this.$message({
                            type: 'success',
                            message: this.$t('message.deleteSuccess')
                        });
                        //异步更新
                        const parent = node.parent;
                        const children = parent.data.children || parent.data;
                        const index = children.findIndex(d => d.id === data.id);
                        children.splice(index, 1);
                    }).catch(error => {
                        console.log(`未删除，原因 => ${error}`)
                    })
                });
            }
        },
    }
</script>

<style scoped>
    .el-alert{
        background:#F7F9FB;
        height:40px;
    }
    .custom-tree-node{
        width: 100%;
    }
    .leftBottom{
        margin-top: 20px;
        width: 100%;
        border: 1px solid #ECEEF2;
        height: 600px;
        overflow-y: auto;
        padding:30px 40px;
        background: #FAFAFA;
    }
    .el-tree{
        margin-top:20px;
        background:#FAFAFA;
    }
    .el-form .el-form-item .el-input{
        width: 80%;
    }
    .floatBtn{
        width:80px;
        position:absolute;
        right:12px;
        top:6px;
    }
</style>
